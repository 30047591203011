
footer {
    background-color: rgba(35, 152, 248, 0.996);
    padding: 10px;
    font-size: 1.4rem;
    max-width: 100%;
};

footer p {
    margin: 2px;
}

