/* Existing styles for larger screens */
.services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: rgb(2, 63, 161);
    justify-content: space-evenly;
    padding: 0px;
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: 800;
}

.services img {
    height: 30vh;
    width: 30vw;
    border-radius: 9%;
}

html, body {width: auto!important; overflow-x: hidden!important} 


/* Updated styles for smaller screens */
@media screen and (max-width: 960px) {

    html, body {width: auto!important; overflow-x: hidden!important} 

    *body{
        background-color: white;
        max-width: 100%;
    }

    .pressure-washing h4{
        color: blue;
    }
    .services {
        
        font-size: 1.8rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        color: aliceblue;
        transition: all 0.3s ease; /* Adjust the duration and timing function as needed */
    }

    /* Define the styles that change on scroll */
    .services {
        /* Define the new styles for when scrolling down */
        /* For example, change background color or font size */
        background-color: white;
        font-size: 24px;
    }

    .services img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 10px;
        margin: 2px;
        max-width: 100%;
        /* background-color: #00076e; */
    }

    /* Override text color for specific elements within .services */
    .services .window h1,
    .services .solar h1,
    .services .gutter h1,
    .services .pressure-washing h1 {
        color: rgb(3, 97, 180);
        padding: 2px;
        margin: 2px;
    }

    /* Additional styles for the pipe character */
    .services .pipes {
        display: none;
    }
}
