body {
    font-size: 1.8rem;
    color: black;
    /* background-color: rgba(169, 214, 251, 0.996); */
    font-family: Arial, Helvetica, sans-serif;
}

html, body {width: auto!important; overflow-x: hidden!important} 

.container {
    font-size: 1.5rem;
    padding: 10px;
    line-height: 1.8rem; 
    width: 100%;
    
}

.about{
    order: 2;
    padding: 10px;
    margin: 5px;
    
   }

.about-p1 {
    /* display: flex;
    width: 49vw;
    height: 55vh;
    font-size: 2rem;
    line-height: 3rem;
    border-radius: 20px;
    padding: 40px;
    margin: 20px; */
    /* border: 5px solid rgba(62, 161, 243, 0.996); 
     background-color: rgba(169, 214, 251, 0.996); */
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 15px;
     order: 3;
}
/* .about-p2 {
    display: flex;
    width: 49vw;
    height: 55vh;
    font-size: 2rem;
    line-height: 3rem;
    border-radius: 20px;
    padding: 40px;
    margin: 20px;
     border: 5px solid rgba(62, 161, 243, 0.996); 
    background-color: rgba(169, 214, 251, 0.996);
    order: 4;
} */


 

@media screen and (max-width: 960px) {

    html, body {width: auto!important; overflow-x: hidden!important} 

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
        margin-top: 10px;
        padding: 7px;
        max-width: 98%;
      
    }

    .about-p1 {
        width: 100%;
        height: 0 auto; /* Change height to auto to allow content to expand vertically */
        font-size: 1rem; /* Adjust font size for smaller screens */
        line-height: 1.2rem; 
        margin: 8px;
        padding: 5px;
        /* border-radius: 30px; */
        /* Adjust padding for smaller screens */
        /* Adjust margin for smaller screens */
    }

    .about {
        font-size: 1.2rem;
    }
}
