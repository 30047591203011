 



.google-maps {
    width: 100%; /* Set the desired width */
    height: 300px; /* Set the desired height */
}

 @media screen and (max-width: 960px){

    html, body {width: auto!important; overflow-x: hidden!important} 

    .google-maps {
        
        width: 100%; /* Set the desired width */
        height: 500px; /* Set the desired height */
    }
 } 