@media screen and (max-width: 960px) {
    html, body {width: auto!important; overflow-x: hidden!important} 

    img{
        height: 40vh !important;
        width: 109vw !important;
        max-width: 100% !important;
        
        
       
    };
}

img{
    border: 2px solid black;
    display: flex;
    justify-content: center;
    height: 70vh;
    width: 100%;
    object-fit:scale-down;
    max-width: 100%;
   
};

html, body {width: auto!important; overflow-x: hidden!important} 
   

