* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  /* background: #19191a; */
  background-color: #01173b;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.faClassAndWinow {
display: flex;
}



.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 115px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  display: none;
  list-style: none;
}

.navbar-icon {
  margin-right: 0.5rem;
  
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #09bef0;
}

.nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem;
  margin-bottom:  20px;
}

.activated {
  color: #09bef0;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
  
};

.faClassAndWinow {
  display: flex;
  justify-content:flex-start;
  align-content: flex-start;
  padding: 20px;
  margin: 5px;
 }

 .faClass {
  margin-right: 150px;
  margin-left: 0px;
  display: flex;
  align-self: center;
  color:  #535efa;;
  background-color: rgb(242, 245, 245);
  border-radius: 10px;
  font-size: 3rem;
  list-style: none;
  border: 4px solid white;
 }

.window-9-5-nav{
  color: white;
  display: none;
}

.phone-link-top::before,
.phone-link::before {
content: none !important;
}

.phone-link-top {
list-style: none !important;
text-decoration: none;



}

/* html, body {width: auto!important; overflow-x: hidden!important}  */


@media screen and (max-width: 960px) {
  /* html, body {width: auto!important; overflow-x: hidden!important}  */

/* .phone-link-top {
  all: unset !important;
} */
 *{
  max-width: 100%;
 }

.navbar .navbar-container .phone-link-top {
  display: none;
 
}

.nav-links {
  color: white;
}

.NavbarItems {
  position: relative;
}

.faClass {
 background-color: rgb(240, 245, 245);
 border-radius: 10px;
 font-size: 2.5rem;
 list-style: none; 
 text-decoration: none;
display: flex;
align-self: center;
color: #535efa;
/* margin: 15px; */
border: 3px solid rgb(123, 136, 252);
margin: 5px;
padding: 0px;
width: 40%;

};

.navbar-window {
  display: none;
}

.faClassAndWinow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 1px;
  margin-right: 100px;
 width: 100%;
 }

 /* .faClassAndWinow {
   display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin-right: 140px;
  width: 100%;
  position: relative; 
  
} */

.window-9-5-nav{
  /* padding: 10px;
  margin-right: px; */
  width: 100%;
  font-size: 0.7rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  display: block;
  white-space: nowrap;
  font-weight: bolder;
  padding-right: 40px;
  margin-right: 70px;
};


.phone-link{
 
text-decoration: none;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}


.nav-menu.active {
  background-color: #00076e;
  /* background: #19191a; */
  left: 0;
  opacity: 1;
  transition: all 0.6s ease;
  z-index: 1;
}

.nav-links {
  text-align: center;
  padding: 2rem;
  width: 100%;
  display: table;
}

.nav-links:hover {
  color: #09bef0;
  transform: scale(1.2);
  transition: all 0.3s ease;
}

.nav-item:hover {
  border: none;
}

.nav-item {
  width: 100%;
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(25%, 50%);
  display: none;
 
}


.menu-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  
  background-color: rgb(246, 249, 248);
}


body {
  width: 100%; /* Ensure the body takes up the full viewport width */
  overflow-x: hidden; /* Hide horizontal scrollbar if necessary */
  /* Add other responsive styles here */
}

container {
  width: 100%; /* Make sure the container is 100% width */
  /* Add other container-specific styles here */
}


}